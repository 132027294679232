import React from "react";
import logo from "./logo.png";
import "./App.css";

function App() {
 const pathname = window.location.pathname;
 return (
  <>
   {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
   <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
    <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
     <div className="flex-shrink-0 flex justify-center">
      <a
       href="https://streapsteak.totalsellingsolutions.com.au"
       className="inline-flex"
      >
       <span className="sr-only">Workflow</span>
       <img className="h-44 w-auto" src={logo} alt="" />
      </a>
     </div>
     <div className="py-16">
      <div className="text-center">
       <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl mb-12">
        We have moved!
       </h1>

       <p className="my-2 text-base text-gray-500">
        Please click the below button to log into StreamSpeak.
       </p>
       <div className="mt-12">
        <a
         href="https://streamspeak.totalsellingsolutions.com.au"
         className="mt-2 text-base font-medium text-white bg-indigo-500 hover:bg-indigo-300 p-4 rounded-md"
        >
         StreamSpeak Login<span aria-hidden="true"> &rarr;</span>
        </a>
       </div>

       <p className="mt-12 text-base text-gray-500">
        For any other questions please feel free to contact TSS HQ on 1300 283
        385
       </p>
      </div>
     </div>
    </main>
   </div>
  </>
 );
}

export default App;
